@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}

